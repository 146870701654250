import { ReactElement } from 'react';

import Richtext from '../../UI/Richtext';
import CTA from '../../UI/Cta';
import { Document } from '@contentful/rich-text-types';
import { IFragmentCtaFields } from 'types/contentful';

export interface SlimHeroProps {
  body?: Document | undefined;
  heading?: string | undefined;
  image: string;
  mobileImage?: string | null;
  cta?: IFragmentCtaFields | undefined;
}

export default function SlimHero({
  body,
  heading,
  image,
  mobileImage,
  cta,
}: SlimHeroProps): ReactElement {
  const mobileSrc = mobileImage || image;

  return (
    <div className="relative h-banner-mobile md:h-banner-desktop my-12">
      <div
        className="absolute inset-0 h-banner-mobile md:h-banner-desktop bg-cover bg-center"
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div
        className="absolute inset-0 h-banner-mobile md:h-banner-desktop bg-cover bg-center md:hidden"
        style={{
          backgroundImage: `url(${mobileSrc})`,
        }}
      />
      <div className="md:w-1/2 h-1/2 md:h-full relative z-10">
        <div className="md:w-3/4 h-full py-6 md:py-0 px-6 md:px-0 flex flex-col justify-center ml-auto z-20">
          <div className="w-3/4">
            <h5 className="text-3xl lg:text-4xl text-primary-actual">{heading}</h5>
          </div>
          {body && (
            <Richtext document={body} classNames="my-4 text-lg lg:text-2xl text-primary-actual" />
          )}
          <div className="w-full max-w-400 md:w-3/4">
            {cta && (
              <CTA {...cta} classNames="bg-white text-base button-text text-primary-actual" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
