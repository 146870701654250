import { ReactElement } from 'react';
import { Document } from '@contentful/rich-text-types';
import RichText from 'components/UI/Richtext';
import CTA from './Cta';
import { IFragmentCtaFields } from 'types/contentful';
import dynamic from 'next/dynamic';

const Slider = dynamic(() => import('react-slick'), {
  ssr: false,
});

interface CarouselProps {
  titleElement?: ReactElement;
  title: string;
  description?: Document | undefined;
  items: ReactElement[];
  aspectRatio?: string;
  style?: string;
  descriptionImage?: string;
  cta?: IFragmentCtaFields | undefined;
}

export default function Carousel({
  titleElement = undefined,
  title = '',
  description = undefined,
  items,
  style = '',
  cta = undefined,
}: CarouselProps): ReactElement {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`py-12 text-primary-actual ${style}`}>
      {!titleElement ? (
        <h5 className={`px-10 md:px-24 text-center font-light text-2xl md:text-4xl`}>{title}</h5>
      ) : (
        titleElement
      )}

      <div className="w-2/3 mx-auto">
        {description && (
          <div
            className={`text-center mt-1 text-lg ${style === 'frames' ? 'hidden md:block' : ''}`}
          >
            <RichText document={description} classNames="text-lg text-primary-actual md:text-2xl" />
          </div>
        )}
        {style === 'carousel-reviews' && (
          <div className="w-1/2 sm:w-1/3 md:w-1/4 mx-auto mt-5 mb-3">
            <img src="/images/star.png" alt="Star" className="mx-auto mw-100" />
          </div>
        )}
        {items && items.length ? (
          <Slider className="my-4" {...settings}>
            {items.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </Slider>
        ) : null}

        {description && (
          <div
            className={`w-4/5 mx-auto text-center mt-1 text-lg ${
              style === 'frames' ? 'block w-full mt-3 md:hidden' : 'hidden'
            }`}
          >
            <RichText document={description} classNames="text-lg text-primary-actual md:text-2xl" />
          </div>
        )}

        {cta && <div className={`text-center mt-5`}>{cta && <CTA {...cta} />}</div>}
      </div>
    </div>
  );
}
